.fc.fc-bootstrap a {
  text-decoration: none;
}

.fc.fc-bootstrap a[data-goto]:hover {
  text-decoration: underline;
}

.fc-bootstrap hr.fc-divider {
  border-color: inherit;
}

.fc-bootstrap .fc-today.alert {
  border-radius: 0;
}

.fc-bootstrap a.fc-event:not([href]):not([tabindex]) {
  color: #fff;
}

.fc-bootstrap .fc-popover.card {
  position: absolute;
}

/* Popover
--------------------------------------------------------------------------------------------------*/
.fc-bootstrap .fc-popover .card-body {
  padding: 0;
}

/* TimeGrid Slats (lines that run horizontally)
--------------------------------------------------------------------------------------------------*/
.fc-bootstrap .fc-time-grid .fc-slats table {
  /* some themes have background color. see through to slats */
  background: none;
}